$primary-color: #5128e2;
$secondary-color: #fd7d3a;
$background-color: linear-gradient(106deg, $primary-color, $secondary-color);
$progressbar-fill-color: $secondary-color;
$progressbar-bg-color: #2d2266;
$white: #fff;
$secondary-text-color: #4f4f4f;
$off-white: #e5e6e7;
$highlight-color: #3afab9;
$error-color: #fb5259;
$transparent-purple: rgba(255, 255, 255, 0.5);

$float-button-color: $highlight-color;
$float-button-color-text: $primary-color;
$transparent-border: $transparent-purple;
