// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$taqe-modulo-teste-app-primary: mat-palette($mat-indigo);
$taqe-modulo-teste-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$taqe-modulo-teste-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$taqe-modulo-teste-app-theme: mat-light-theme(
    (
        color: (
            primary: $taqe-modulo-teste-app-primary,
            accent: $taqe-modulo-teste-app-accent,
            warn: $taqe-modulo-teste-app-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($taqe-modulo-teste-app-theme);

// @import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/bootstrap/scss/bootstrap.scss';

@import '~video.js/dist/video-js.css';
@import '~videojs-record/dist/css/videojs.record.css';

@import './assets/scss/animations';
@import './assets/scss/colors';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: $primary-color;
}
